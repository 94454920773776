@import "SharedStyles/Fonts.scss";

:root {
  --global-font: "Rubik", sans-serif;
}

.instructions-container {
  color: #fff;
  font-family: var(--global-font);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;

  .title {
    font-size: 30px;
    color: black;
  }
  .description {
    font-size: 24px;
    color: black;
  }
}

.launch-button-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .launch-button {
    font-family: var(--global-font);
    white-space: pre-wrap;
    font-size: 24px;
    background-color: #3498db;
    color: #fff;
    line-height: 30px;
    text-align: center;
    box-shadow: 0 0 0 0 rgba(#3498db, 0.5);
    animation: pulse 1.5s infinite;
    border-radius: 3.35rem;
    border: none;
    font-size: 1.8rem;
    padding: 1rem 2rem;
    font-weight: 600;
  }
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 50px rgba(#fff, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(#fff, 0);
  }
}
