@import "SharedStyles/Fonts.scss";

:root {
  --global-font: "Rubik", sans-serif;
}

.scan-container {
  padding-top: 60px;
  flex: 1;
  text-align: center;
  color: white;
  flex-direction: column;
  display: flex;

  .link-button {
    font-family: var(--global-font);
    font-size: 1em;
    background-color: transparent;
    border: 2px solid #3484f7;
    color: black;
    padding: 15px;
    margin: 10px 100px;
    border-radius: 12px;
  }

  input[type="file"] {
    display: none;
  }

  .instructions-container {
    font-family: var(--global-font);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      text-decoration: underline;
      margin-bottom: 5px;
    }
  }

  .progress-bar-container {
    width: 115px;
    height: 115px;
    margin: auto;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .scan-button-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .scan-button {
      font-family: var(--global-font);
      font-size: 24px;
      text-transform: uppercase;
      background-color: #fff;
      color: #3498db;
      width: 115px;
      height: 115px;
      line-height: 115px;
      border-radius: 50%;
      text-align: center;
      box-shadow: 0 0 0 0 rgba(#fff, 0.5);
      animation: pulse 1.5s infinite;

      &.loading {
        width: 103px;
        height: 103px;
        border: 12px solid #f3f3f3;
        border-top: 12px solid #3498db;
        animation: spin 2s linear infinite;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .help-link {
    font-size: 1em;
    font-family: var(--global-font);
    background-color: white;
    border: none;
    color: black;
    padding: 15px;
    margin: 10px 50px;
    border-radius: 12px;
    white-space: pre-wrap;
  }
}

.help-container {
  font-family: var(--global-font);
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;

  &.show {
    display: inline;
  }

  .help-modal {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    .instructions-gif {
      width: auto;
      height: 80%;
      position: absolute;
      top: 90px;
      img {
        height: 100%;
        width: auto;
      }
    }
    .close-button {
      position: absolute;
      top: -20px;
      right: -20px;
      color: grey;
      font-size: 40px;
      z-index: 5;
      background-color: white;
      border-radius: 20px;
      width: 40px;
      height: 40px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.result-container {
  font-family: var(--global-font);
  position: fixed;
  bottom: calc(-100vh + 600px - 700px);
  right: 0;
  left: 0;
  background-color: white;
  background-image: linear-gradient(#4b84c5, #4b84c5 100px, transparent 100px);
  height: 100vh;
  border-radius: 24px 24px 0 0;
  transition: bottom 0.4s cubic-bezier(0.5, 1.24, 1, 1.13);
  padding: 12px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);

  &.entered {
      bottom: calc(-100vh + 600px - 470px);
      &.full {
        bottom: calc(-100vh + 600px - 150px);
      }
    }


  &.fullImage {
      padding: 0px;
      overflow-y: scroll;   
      background-image: none;
      background-color: white;
    &.entered {
      bottom: calc(-100vh + 600px - 470px);
      &.full {
        bottom: -200px;
      }
    }
   }

  .full-image{
    width:100vw;
  }

  .full-image-filler {
    height: 150px;
    background-color: white;
  }

  .result-card-title {
    color: white;
    font-size: 1.2em;
    padding-bottom: 4px;
    margin-left: 4px;
  }

  .results-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    background-color: white;

    .header {
      font-size: 2em;
      &.danger {
        color: #dc3545;
      }
      &.success {
        color: #28a745;
      }
      &.warning {
        color: #ffc107;
      }
    }
    .body {
      flex: 1;
      padding-top: 2px;
    }
    .footer {
      font-size: 0.7em;
    }
  }

  .result-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 12px;
    position: relative;

    .title {
      margin-top: 12px;
      margin-bottom: 6px;
      text-align: center;
      color: black;
    }
    .subtitle {
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
      color: #525252;
    }
    .footnote {
      padding-top: 12px;
      font-size: 0.9em;
      text-align: center;
      color: #727272;
    }
    .dummy-button {
      font-family: var(--global-font);
      font-size: 1em;
      background-color: #d8d8d8;
      border: none;
      color: #898989;
      padding: 15px;
      margin: 10px 50px;
      border-radius: 12px;
    }
    .image-wrapper {
      position: relative;
      margin: auto;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      background-color: white;
      border-radius: 12px;
      padding: 12px 24px;

      .product-image {
        height: 180px;
        width: auto;
        margin: auto;
      }
      .link-icon {
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 1.5em;
      }
    }
    .read {
      margin-top: 12px;
      margin-bottom: 60px;
      text-align: center;
      color: black;
    }

    .write-button {
      font-family: var(--global-font);
      font-size: 24px;
      background-color: #4b84c5;
      border: none;
      color: white;
      padding: 20px;
      margin: 30px;
      border-radius: 12px;
      &:hover {
        transform: scale(1.1);
      }
    }
    .close-button {
      font-family: var(--global-font);
      font-size: 20px;
      background-color: grey;
      border: none;
      color: white;
      padding: 15px;
      margin-left: 80px;
      margin-right: 80px;
      border-radius: 12px;
      &:hover {
        transform: scale(1.1);
      }
    }

    .write-form-container {
      position: fixed;
      bottom: -700px;
      height: 600px;
      transition: bottom 0.4s cubic-bezier(0.5, 1.24, 1, 1.13);
      padding: 12px;
      background-color: white;
      right: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      &.entered {
        bottom: -400px;
      }

      .write-button-save {
        position: absolute;
        top: 140px;
        right: 8vw;
        width: 60px;
        color: #4b84c5;
        background-color: transparent;
        border-color: transparent;
        font-size: 100%;
      }

      .write-input {
        font-family: var(--global-font);
        width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 150px;
        border: 2px solid grey;
        border-radius: 12px;
        font-size: 100%;
      }
    }
  }
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 50px rgba(#fff, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(#fff, 0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
