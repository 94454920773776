:root {
  --vertical-padding: 3px;
  --button-height: 56px;
  --edge-padding: 12px;
}

.ben-button {
  --button-full-height: calc(
    calc(
        var(--button-height) + var(--vertical-padding) + var(--vertical-padding)
      ) * -1
  );
  position: absolute;
  height: var(--button-height);
  top: calc(var(--button-full-height) + var(--button-full-height));
  right: calc(-100vw + var(--edge-padding));
  &.reverse {
    left: calc(var(--edge-padding) * -1);
    top: var(--button-full-height);
  }
  .ben-button-shape {
    fill: #666666;
    &.disabled {
      fill: #999999;
    }
  }
  .ben-button-text {
    fill: #cccccc;
    font-size: 24px;
    dominant-baseline: middle;
    .ben-button-x {
      vertical-align: bottom;
      font-size: 30px !important;
      display: none;
    }
    .ben-button-arrow {
      vertical-align: bottom;
      font-size: 40px !important;
      display: none;
    }
  }
}
