@import "SharedStyles/Fonts.scss";

.embedded-scan-page.cadica {
  height: 100%;
  display: flex;
  flex-direction: column;

  header {
    justify-content: center;
    .logo-container {
      padding: 10px;
      img {
        height: 40px;
      }
    }
    .title-container {
      display: none;
    }
  }

  .instructions-container {
    color: black;
  }

  .scan-button-container {
    .scan-button {
      background-color: #433f3a;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#433f3a, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #242627;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: #433f3a;
    color: #fff;
  }

  .result-container {
    background-color: #fdfdfd;
  }
}

@keyframes buttonpulse {
  70% {
    box-shadow: 0 0 0 50px rgba(#2e71b6, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(#2e71b6, 0);
  }
}
