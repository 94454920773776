.instant-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: black !important;
  height: 45px;
  z-index: 10;
  box-shadow: 0px 2px 5px rgba(114, 114, 114, 0.4);

  .instant-header-container {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

  .app-icon-container {
    margin: 10px;
    display:flex;
    align-items: center;
    img {
      height: 28px;
      margin:10px;
    }
    .app-title-container {
      .app-title{
        color:white;
        margin:2px;
        font-size: 0.8em;
      }
      .app-subtitle{
        color: #87868d;
        margin:2px;
        font-weight:normal;
      }
    }
  }



  .app-button-container{
        .app-button {
          background-color: #0b84fe;
          color: white;
          margin: 10px 10px;
          border-radius: 14px;
          height: 28px;
          width: 68px;
          border-color: transparent;
          font-weight: bold;
          text-align: center;
          font-size: 14px;
        }
}}
}
