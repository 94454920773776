@import "SharedStyles/Fonts.scss";

:root {
  --global-font: "Rubik", sans-serif;
}

.scan-container-recommerce {
  padding-top: 60px;
  flex: 1;
  text-align: center;
  color: white;
  flex-direction: column;
  display: flex;

  input[type="file"] {
    display: none;
  }

  .instructions-container-recommerce {
    font-family: var(--global-font);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;

    .title-recommerce {
      text-decoration: underline;
      margin-bottom: 5px;
    }
  }

  .progress-bar-container {
    width: 115px;
    height: 115px;
    margin: auto;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .scan-button-container-recommerce {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .scan-button-recommerce {
      font-family: var(--global-font);
      font-size: 24px;
      text-transform: uppercase;
      background-color: #666666;
      color: white;
      width: 115px;
      height: 115px;
      line-height: 115px;
      border-radius: 50%;
      text-align: center;
      box-shadow: 0 0 0 0 rgba(#666666, 0.5);
      animation: pulse 1.5s infinite;

      &.loading {
        width: 103px;
        height: 103px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #666666;
        animation: spin 2s linear infinite;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .help-link-recommerce {
    font-size: 1em;
    font-family: var(--global-font);
    background-color: #666666;
    border: none;
    color: white;
    padding: 15px;
    margin: 10px 50px;
    border-radius: 12px;
    white-space: pre-wrap;
  }
}

.help-container-recommerce {
  font-family: var(--global-font);
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;

  &.show {
    display: inline;
  }

  .help-modal-recommerce {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    .instructions-gif-recommerce {
      width: auto;
      height: 80%;
      position: absolute;
      top: 90px;
      img {
        height: 100%;
        width: auto;
      }
    }
    .close-button-recommerce {
      position: absolute;
      top: -20px;
      right: -20px;
      color: grey;
      font-size: 40px;
      z-index: 5;
      background-color: white;
      border-radius: 20px;
      width: 40px;
      height: 40px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.result-container-recommerce {
  --card-height: calc(
    100% - 20px - 60px - 24px
  ); //100% because VW is unreliable on mobile.
  //  -20px so there's a 20px border where the user can tap.
  //  -60px because the header is 60px big.
  //  -24px because there's a 12px padding

  //border: 3px solid green;
  font-family: var(--global-font);
  position: fixed;
  bottom: calc(
    -1 * var(--card-height) - 100px
  ); //Height of card minus 100px buffer
  right: 0;
  left: 0;
  background-color: white;
  background-image: linear-gradient(#666666, #ffffff 100px, transparent 100px);
  height: var(--card-height);
  border-radius: 24px 24px 0 0;
  transition: bottom 0.6s cubic-bezier(0.68, 0.04, 0.58, 1.33);
  padding: 12px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);

  .result-buttons {
    position: absolute;
    bottom: 0px;
  }

  &.entered {
    bottom: calc(-1 * var(--card-height) + 130px); // Show 130px of the card
    &.full {
      bottom: 0px;
    }
  }

  .result-card-title {
    color: white;
    font-size: 1.2em;
    padding-bottom: 4px;
    margin-left: 4px;
  }

  .results-card-recommerce {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    background-color: white;

    .header-recommerce {
      font-size: 1.5em;
      text-align: center;
      &.danger {
        color: #dc3545;
      }
      &.success {
        color: #28a745;
      }
      &.warning {
        color: #ffc107;
      }
    }
    .body-recommerce {
      flex: 1;
      padding-top: 2px;
    }
  }

  .result-body-recommerce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    position: relative;

    .pretty-button {
      font-size: 18px;
      height: 56px;
      background-color: #666666;
      border: none;
      color: #cccccc;
      padding: 15px;
      margin: 10px 50px;
      border-radius: 20px;
    }

    .contract-information {
      white-space: pre-wrap;
    }

    .basic-flex {
      flex: 1;
      white-space: pre-wrap;
    }

    .basic-padding {
      padding: 0px;
      margin: 6px;
    }

    .user-price {
      margin: auto;
      font-size: 3em;
    }

    .recommerce-product-info {
      margin: auto;
      font-size: 1.5em;
      padding: 10px;
    }

    .floating-label {
      flex: 1;
      font-size: 1px;
      padding: 0px;
      margin: 0px;
    }
    .recommerce-image-outside-wrapper {
      padding-bottom: 12px;
      margin: auto;
      margin-top: 12px;
      .recommerce-image-wrapper {
        position: relative;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        background-color: white;
        border-radius: 12px;
        padding: 12px 24px;

        .recommerce-product-image {
          height: 300px;
          max-height: 25vh;
          width: auto;
          margin: auto;
        }
      }
    }
    .recommerce-image-wrapper-compact {
      padding-bottom: 12px;
      display: inline-block;
      position: relative;
      margin: auto;
      margin-top: 12px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      background-color: white;
      border-radius: 12px;
      padding: 10px;

      .recommerce-product-image {
        height: 110px;
        width: auto;
        margin: auto;
      }
    }
    .compact-product-container {
      flex: 1;
      padding: 10px;
      margin: auto;

      .compact-text {
        display: inline-block;
        padding: 10px;
        vertical-align: top;
        font-size: 1.5em;
      }
    }
  }
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 50px rgba(#fff, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(#fff, 0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
