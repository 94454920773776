@import "SharedStyles/Fonts.scss";

.scan-container.tag-writer {
  padding-top: 60px;
  flex: 1;
  text-align: center;
  color: white;
  flex-direction: column;
  display: flex;

  input[type="file"] {
    display: none;
  }

  .instructions-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      text-decoration: underline;
      margin-bottom: 5px;
    }
  }

  .scan-button-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .scan-button {
      font-size: 24px;
      text-transform: uppercase;
      background-color: #fff;
      color: #4b84c5;
      width: 115px;
      height: 115px;
      line-height: 115px;
      border-radius: 50%;
      text-align: center;
      box-shadow: 0 0 0 0 rgba(#fff, 0.5);
      animation: pulse 1.5s infinite;

      &.loading {
        width: 103px;
        height: 103px;
        border: 12px solid #f3f3f3;
        border-top: 12px solid #3498db;
        animation: spin 2s linear infinite;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .help-link {
    text-decoration: underline;
  }
}

.help-container {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;

  &.show {
    display: inline;
  }

  .help-modal {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    .instructions-gif {
      width: auto;
      height: 80%;
      position: absolute;
      top: 90px;
      img {
        height: 100%;
        width: auto;
      }
    }
    .close-button {
      position: absolute;
      top: -20px;
      right: -20px;
      color: grey;
      font-size: 40px;
      z-index: 5;
      background-color: white;
      border-radius: 20px;
      width: 40px;
      height: 40px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.result-container.tag-writer {
  position: fixed;
  bottom: -700px;
  right: 0;
  left: 0;
  background-color: #f3f3f3;
  background-image: none;
  height: 600px;
  border-radius: 24px 24px 0 0;
  transition: bottom 0.4s cubic-bezier(0.5, 1.24, 1, 1.13);
  padding: 12px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);

  &.entered {
    bottom: -470px;
    &.full {
      bottom: -130px;
    }
  }

  .result-card-title {
    color: white;
    font-size: 1.2em;
    padding-bottom: 4px;
    margin-left: 4px;
  }

  .results-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    background-color: white;

    .header {
      font-size: 2em;
      &.danger {
        color: #dc3545;
      }
      &.success {
        color: #28a745;
      }
      &.warning {
        color: #ffc107;
      }
    }
    .body {
      flex: 1;
      padding-top: 2px;
    }
    .footer {
      font-size: 0.7em;
    }
  }

  .result-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    position: relative;

    .title {
      margin-top: 12px;
      margin-bottom: 6px;
      text-align: center;
      color: black;
    }
    .subtitle {
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
      color: #525252;
    }
    .footnote {
      padding-top: 12px;
      font-size: 0.9em;
      text-align: center;
      color: #727272;
    }
    .input-label {
      margin-bottom: 2px;
    }
    .input {
      border-radius: 5px;
      // color: #4b515d;
      // border: 1px solid #B8B6B6;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      background-color: #fefefe;
      border: 1px solid #f2f2f2;
      // box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
      height: 20px;
      // border: none;
      outline: none;
      padding: 10px;
      font-size: 12pt;
      margin-bottom: 5px;
    }
    .submit-button {
      border-radius: 5px;
      // color: #4b515d;
      // border: 1px solid #B8B6B6;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      background-color: #4b84c5;
      border: 1px solid #f2f2f2;
      // box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
      height: 40px;
      margin-top: 10px;
      color: white;
      // border: none;
      outline: none;
      padding: 10px;
      font-size: 12pt;
      margin-bottom: 5px;
    }
  }
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 50px rgba(#fff, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(#fff, 0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
