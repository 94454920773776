@import "SharedStyles/Fonts.scss";

.embedded-scan-page.recommerce {
  --global-color-dark: #666666;
  --global-color-light: #666666;

  header {
    background-color: white;
    .logo-container {
      padding: 15px;
      padding-right: 40px;
      img {
        height: 30px;
      }
    }
  }
}

.embedded-scan-page.unismart-recommerce {
  --global-color-dark: #1b451a;
  --global-color-light: white;

  header {
    background-color: white;
    .logo-container {
      padding: 10px;
      padding-right: 40px;
      img {
        height: 30px;
        margin-top: 5px;
      }
    }
  }

  .scan-button-container-recommerce {
    .scan-button-recommerce {
      background-color: var(--global-color-dark);
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#1b451a, 0.5);
    }
  }

  .help-link-recommerce {
    background-color: var(--global-color-dark);
    color: #fff;
  }

  .result-container-recommerce {
    background-image: linear-gradient(
      var(--global-color-dark),
      #ffffff 100px,
      transparent 100px
    );

    .results-card-recommerce {
      .header-recommerce {
        font-size: 2em;
        text-align: left;
      }
    }
    .result-body-recommerce {
      .pretty-button {
        background-color: var(--global-color-dark);
      }
      .compact-product-container {
        .compact-text {
          font-size: 1.2em;
          width: calc(50vw - 50px);
        }
      }
    }
  }

  .ben-button {
    .ben-button-shape {
      fill: var(--global-color-dark);
    }
  }
}

.embedded-scan-page.test-class {
  header {
    .title-container {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
    }
    .title {
      margin-left: 0 !important;
    }
    background-color: #fff; // header background color
  }

  .scan-container {
    background-color: #fff; // page background color
  }

  .instructions-container {
    color: black; // instructions text color
  }

  .scan-button-container {
    .scan-button {
      background-color: #1b451a;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#1b451a, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #338832;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: #1b451a;
    color: #fff;
  }
}

.embedded-scan-page.arylla {
  .scan-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to bottom right, #029e58, #4b84c5);
  }

  .instructions-container {
    color: white; // instructions text color
  }

  .scan-button-container {
    .scan-button {
      background-color: #ffffff;
      color: #4b84c5;
      box-shadow: 0 0 0 0 rgba(#ffffff, 0.5);

      &.loading {
        width: 103px;
        height: 103px;
        border: 12px solid #f3f3f3;
        border-top: 12px solid #3498db;
        animation: spin 2s linear infinite;
      }
    }
  }
  .help-link {
    background-color: #ffffff;
    color: #4b84c5;
  }
}

.embedded-scan-page.artfan {
  --global-font: "Lato", sans-serif;
  header {
    justify-content: center;
    .logo-container {
      padding: 10px;
      img {
        height: 40px;
      }
    }
    .title-container {
      display: none;
    }

    background-color: #fff; // header background color
  }
  .instructions-container {
    .step {
      margin: 8px 10px;
    }
  }
  .scan-button-container {
    .scan-button {
      background-color: #433f3a;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#433f3a, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #242627;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: #433f3a;
    color: #fff;
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-color: #fdfdfd;
    background-image: linear-gradient(
      #242627,
      #242627 100px,
      transparent 100px
    );
  }

  .result-body {
    .footnote {
      display: none;
    }
  }

  .image-wrapper {
    .link-icon {
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 0.75em;
    }
  }
}

.embedded-scan-page.arcteryx {
  .scan-button-container {
    .scan-button {
      background-color: black;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(black, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid black;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: black;
    color: #fff;
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-color: #fdfdfd;
    background-image: linear-gradient(black, black 100px, transparent 100px);
  }

  .result-body {
    .title {
      font-size: 130%;
    }
    .footnote {
      display: none;
    }
  }
}

.embedded-scan-page.avery {
  header {
    background-color: white;
    .logo-container {
      padding: 15px;
      img {
        height: 30px;
      }
    }
  }

  --global-color-dark: white;
  --global-color-light: black;
  --text-color: black;

  background-color: var(--global-color-dark);

  .link-button {
    border: 2px solid var(--global-color-light);
    color: var(--text-color);
  }

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(black, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }

    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .subtitle {
      margin: 10px;
      text-align: center;
      white-space: pre-wrap;
      font-size: 0.9em;
    }
    .footnote {
      display: none;
    }
  }
}

.embedded-scan-page.cadica-dark {
  header {
    justify-content: center;
    .title-container {
      display: none;
    }
  }

  --global-color-dark: #4a4a4a;
  --global-color-light: white;
  --text-color: white;

  background-color: var(--global-color-dark);

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: black;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(white, 0.5);
      color: black;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .footnote {
      display: none;
    }
  }
}

.embedded-scan-page.dlr {
  --global-color-dark: #2f354e;
  --global-color-light: #2f354e;

  header {
    background-color: var(--global-color-light);
    color: white;
  }

  .instructions-container {
    .step {
      margin: 4px;
    }
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#2f354e, 0.5);
      color: white;
      &.loading {
        border: 6px solid var(#f3f3f3);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .help-link {
    background-color: var(--global-color-light);
    color: white;
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
  }
}

.embedded-scan-page.goat {
  header {
    justify-content: center;
    .logo-container {
      padding: 10px;
      img {
        height: 40px;
      }
    }
    .title-container {
      display: none;
    }

    background-color: #fff; // header background color
  }

  .scan-button-container {
    .scan-button {
      background-color: #433f3a;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#433f3a, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #242627;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: #433f3a;
    color: #fff;
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-color: #fdfdfd;
    background-image: linear-gradient(
      #242627,
      #242627 100px,
      transparent 100px
    );
  }

  .result-body {
    .title {
      font-size: 140%;
    }

    .footnote {
      display: none;
    }

    .image-wrapper {
      .link-icon {
        display: none;
      }
    }
  }
}

.embedded-scan-page.fasiculus {
  --global-font: "Montserrat", sans-serif;
  header {
    background-color: #439f47;
  }

  --global-color-dark: #439f47;
  --global-color-light: #ffffff;
  --text-color: black;

  background-color: var(--global-color-dark);

  .instructions-container {
    .title {
      color: var(--global-color-light);
    }
    .description {
      color: var(--global-color-light);
    }
  }

  .launch-button-container {
    justify-content: flex-start;
    .launch-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#ffffff, 0.5);
      color: var(--global-color-dark);
    }
  }
}

.embedded-scan-page.isik {
  header {
    justify-content: center;
    .logo-container {
      padding: 10px;
      img {
        height: 40px;
      }
    }

    background-color: #fff; // header background color
  }

  .scan-container {
    background-color: #fff; // page background color
  }

  .instructions-container {
    color: black; // instructions text color
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      #242627,
      #242627 100px,
      transparent 100px
    );
  }

  .scan-button-container {
    .scan-button {
      background-color: #433f3a;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#433f3a, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #242627;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: #433f3a;
    color: #fff;
  }

  .result-body {
    .title {
      font-size: 140%;
    }

    .subtitle {
      font-size: 100%;
    }

    .footnote {
      display: none;
    }
  }
}

.embedded-scan-page.jv-dawnrays {
  --global-color-dark: white;
  --global-color-light: #244e44;
  --text-color: #244e44;

  background-color: var(--global-color-dark);

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#244e44, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    &.entered {
      &.full {
        bottom: calc(-100vh + 600px - 80px);
      }
    }
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      text-align: left;
    }
    .subtitle {
      text-align: left;
      white-space: pre-wrap;
      font-size: 1em;
    }
  }
}

.embedded-scan-page.jv-dawnrays-basic {
  --global-color-dark: #b4bab8;
  --global-color-light: #244e44;
  --text-color: #244e44;

  background-color: var(--global-color-dark);

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#244e44, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    &.entered {
      &.full {
        bottom: calc(-100vh + 600px - 80px);
      }
    }
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      text-align: left;
    }
    .subtitle {
      text-align: left;
      white-space: pre-wrap;
      font-size: 1em;
    }
  }
}

.embedded-scan-page.jv-dawnrays-rev {
  --global-color-dark: #638583;
  --global-color-light: #244e44;
  --text-color: white;

  background-color: var(--global-color-dark);

  .instructions-container {
    color: var(--text-color);
    white-space: pre-wrap;
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#244e44, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    &.entered {
      &.full {
        bottom: calc(-100vh + 600px - 80px);
      }
    }
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      text-align: left;
    }
    .subtitle {
      text-align: left;
      white-space: pre-wrap;
      font-size: 1em;
    }
  }
}

.embedded-scan-page.jetsci {
  --global-color-dark: white;
  --global-color-light: #ea088c;
  --text-color: black;

  background-color: var(--global-color-dark);

  .link-button {
    border: 2px solid var(--global-color-light);
    color: var(--text-color);
  }

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#ea088c, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .subtitle {
      margin: 10px;
      text-align: center;
      white-space: pre-wrap;
      font-size: 0.9em;
    }
  }
}

.embedded-scan-page.jv-pfizer {
  --global-color-dark: white;
  --global-color-light: #2e3daa;
  --text-color: #2e3daa;

  background-color: var(--global-color-dark);

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#2e3daa, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    &.entered {
      &.full {
        bottom: calc(-100vh + 600px - 80px);
      }
    }
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      text-align: left;
    }
    .subtitle {
      text-align: left;
      white-space: pre-wrap;
      font-size: 1em;
    }
  }
}

.embedded-scan-page.jv-pfizer-basic {
  --global-color-dark: #bcbcc0;
  --global-color-light: #2e3daa;
  --text-color: #2e3daa;

  background-color: var(--global-color-dark);

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#2e3daa, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    &.entered {
      &.full {
        bottom: calc(-100vh + 600px - 80px);
      }
    }
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      text-align: left;
    }
    .subtitle {
      text-align: left;
      white-space: pre-wrap;
      font-size: 1em;
    }
  }
}

.embedded-scan-page.jv-pfizer-rev {
  --global-color-dark: #5864bb;
  --global-color-light: #2e3daa;
  --text-color: white;

  background-color: var(--global-color-dark);

  .instructions-container {
    color: var(--text-color);
    white-space: pre-wrap;
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#2e3daa, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    &.entered {
      &.full {
        bottom: calc(-100vh + 600px - 80px);
      }
    }
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      text-align: left;
    }
    .subtitle {
      text-align: left;
      white-space: pre-wrap;
      font-size: 1em;
    }
  }
}

.embedded-scan-page.lole {
  --global-color-dark: #ffc72c;
  --global-color-light: #ffc72c;

  header {
    background-color: var(--global-color-light);
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#ffc72c, 0.5);
      color: black;
      &.loading {
        border: 6px solid var(#f3f3f3);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .scan-button-container-recommerce {
    .scan-button-recommerce {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#ffc72c, 0.5);
      color: black;
      &.loading {
        border: 6px solid var(#f3f3f3);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .help-link {
    background-color: var(--global-color-light);
    color: black;
  }

  .help-link-recommerce {
    background-color: var(--global-color-light);
    color: black;
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
  }

  .result-container-recommerce {
    background-image: linear-gradient(
      var(--global-color-dark),
      #ffffff 100px,
      transparent 100px
    );

    .results-card-recommerce {
      .header-recommerce {
        font-size: 2em;
        text-align: left;
      }
    }
    .result-body-recommerce {
      .pretty-button {
        background-color: var(--global-color-dark);
        color: black;
      }
      .compact-product-container {
        .compact-text {
          font-size: 1.2em;
          width: calc(50vw - 50px);
        }
      }
    }
  }

  .results-card {
    .header {
      font-size: 1.1em;
    }
  }

  .result-body {
    .subtitle {
      white-space: pre-wrap;
      text-align: left;
      font-size: 1em;
    }
    .footnote {
      display: none;
    }
    .image-wrapper {
      .link-icon {
        display: none;
      }
    }
  }

  .ben-button {
    .ben-button-shape {
      fill: var(--global-color-dark);
    }
    .ben-button-text {
      fill: black;
    }
  }
}

.embedded-scan-page.luminescence {
  --global-color-dark: white;
  --global-color-light: #0854a4;
  --text-color: black;

  header {
    background-color: white;
  }
  background-color: var(--global-color-dark);

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#2e3daa, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      text-align: left;
    }
    .subtitle {
      text-align: left;
      white-space: pre-wrap;
      font-size: 1em;
    }
  }
}

.embedded-scan-page.redcross {
  header {
    .logo-container {
      padding: 10px;
    }
  }

  --global-color-dark: #ffffff;
  --global-color-light: #ee0000;
  --text-color: #000000;

  background-color: var(--global-color-dark);

  .link-button {
    border: 2px solid var(--global-color-light);
    color: var(--text-color);
  }

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#ee0000, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    &.entered {
      &.full {
        bottom: calc(-100vh + 600px - 80px);
      }
    }
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      text-align: center;
      font-size: 1em;
    }
    .subtitle {
      margin: 20px;
      text-align: center;
      white-space: pre-wrap;
      font-size: 1em;
    }
    .footnote {
      display: none;
    }
  }
}

.embedded-scan-page.ribkoff {
  header {
    justify-content: center;
    .logo-container {
      padding: 10px;
      img {
        height: 40px;
      }
    }
    .title-container {
      display: none;
    }

    background-color: #fff; // header background color
  }

  .scan-container {
    background-color: #fff; // page background color
  }

  .instructions-container {
    color: black; // instructions text color
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      #242627,
      #242627 100px,
      transparent 100px
    );
  }

  .scan-button-container {
    .scan-button {
      background-color: #433f3a;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#433f3a, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #242627;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: #433f3a;
    color: #fff;
  }
}

.embedded-scan-page.rpac-trial {
  --global-color-dark: #cf112b;
  --global-color-light: #cf112b;

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#cf112b, 0.5);
      &.loading {
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .help-link {
    background-color: var(--global-color-light);
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
  }

  .results-card {
    .header {
      &.success {
        color: black;
      }
    }
  }

  .result-body {
    .footnote {
      display: none;
    }
  }
}

.embedded-scan-page.sample {
  --global-color-dark: #666666;
  --global-color-light: #666666;

  header {
    background-color: white;
    .logo-container {
      padding: 15px;
      padding-right: 40px;
      img {
        height: 30px;
      }
    }
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#666666, 0.5);
      color: white;
      &.loading {
        border: 6px solid var(#f3f3f3);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .help-link {
    background-color: var(--global-color-light);
    color: white;
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
  }

  .result-body {
    .title {
      margin-top: 0px;
      margin-bottom: 6px;
      font-size: 1.4em;
      text-align: left;
    }
    .subtitle {
      white-space: pre-wrap;
      text-align: left;
      font-size: 1em;
    }
    .footnote {
      display: none;
    }
    .image-wrapper {
      padding: 6px 12px;
    }
  }
}

.embedded-scan-page.sample-video {
  --global-color-dark: #666666;
  --global-color-light: #666666;
  --text-color: black;

  .link-button {
    border: 2px solid var(--global-color-light);
    color: var(--text-color);
  }

  header {
    background-color: white;
    .logo-container {
      padding: 15px;
      padding-right: 40px;
      img {
        height: 30px;
      }
    }
  }

  .instructions-container-video {
    color: #000000;
  }

  .scan-button-container-video {
    .scan-button-video {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#666666, 0.5);
      color: white;
      &.loading {
        border: 6px solid var(#f3f3f3);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .help-link-video {
    background-color: var(--global-color-light);
    color: white;
  }

  .result-container-video {
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
  }

  .result-body-video {
    .title-video {
      margin-top: 0px;
      margin-bottom: 6px;
      font-size: 1.4em;
      text-align: left;
    }
    .subtitle-video {
      white-space: pre-wrap;
      text-align: left;
      font-size: 1em;
    }
    .footnote-video {
      display: none;
    }
    .image-wrapper-video {
      padding: 6px 12px;
    }
  }
}

.embedded-scan-page.sample-video-invert {
  --global-color-dark: white;
  --global-color-light: white;
  --text-color: white;

  background-color: #666666;

  .link-button {
    border: 2px solid var(--global-color-light);
    color: var(--text-color);
  }

  header {
    background-color: white;
    .logo-container {
      padding: 15px;
      padding-right: 40px;
      img {
        height: 30px;
      }
    }
  }

  .instructions-container-video {
    color: var(--text-color);
  }

  .scan-button-container-video {
    .scan-button-video {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(white, 0.5);
      color: #666666;

      &.loading {
        border: 6px solid var(#666666);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .help-link-video {
    background-color: var(--global-color-light);
    color: black;
  }

  .result-container-video {
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
  }

  .result-body-video {
    .title-video {
      margin-top: 0px;
      margin-bottom: 6px;
      font-size: 1.4em;
      text-align: left;
    }

    .subtitle-video {
      white-space: pre-wrap;
      text-align: left;
      font-size: 1em;
    }

    .footnote-video {
      display: none;
    }

    .image-wrapper-video {
      padding: 6px 12px;
    }
  }
}

.embedded-scan-page.scanner {
  header {
    .logo-container {
      display: none;
    }
    .title-container {
      .title {
        margin-left: auto;
      }
    }
  }

  --global-color-dark: white;
  --global-color-light: #02143c;
  --text-color: black;

  background-color: var(--global-color-dark);

  .link-button {
    border: 2px solid var(--global-color-light);
    color: var(--text-color);
  }

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#02143c, 0.5);
      color: #ffffff;

      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }

    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );

    .read {
      color: var(--global-color-dark);
    }

    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .subtitle {
      margin: 10px;
      text-align: center;
      white-space: pre-wrap;
      font-size: 0.9em;
    }

    .footnote {
      display: none;
    }
  }
}

.embedded-scan-page.stamp {
  --global-color-dark: #4b84c5;
  --global-color-light: #4b84c5;

  .result-body {
    .footnote {
      display: none;
    }
  }

  .instructions-container-video {
    color: #000000;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#4b84c5, 0.5);
      color: white;
      &.loading {
        border: 6px solid var(#f3f3f3);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .scan-button-container-video {
    .scan-button-video {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#4b84c5, 0.5);
      color: white;
      &.loading {
        border: 6px solid var(#f3f3f3);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .help-link {
    background-color: var(--global-color-light);
    color: white;
  }

  .help-link-video {
    background-color: var(--global-color-light);
    color: white;
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
  }

  .result-container-video {
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );

    .result-body-video {
      .footnote-video {
        display: none;
      }
    }
  }
}

.embedded-scan-page.therealreal {
  --global-color-dark: #383434;
  --global-color-light: #383434;

  .instructions-container {
    color: var(--global-color-dark);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: white;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#383434, 0.5);

      &.loading {
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
    .results-card {
      .header {
        &.success {
          display: none;
        }
      }
      .body {
        font-size: 1.5em;
      }
    }
    .read {
      color: var(--global-color-dark);
    }
    .write-button {
      background-color: var(--global-color-light);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-light);
      }
    }
  }

  .result-body {
    .write-button {
      background-color: var(--global-color-light);
    }
  }
}

.embedded-scan-page.trove {
  --global-color-dark: #383634;
  --global-color-light: #b79d82;

  .instructions-container {
    color: var(--global-color-dark);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: white;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#b79d82, 0.5);

      &.loading {
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
    .read {
      color: var(--global-color-dark);
    }
    .write-button {
      background-color: var(--global-color-light);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-light);
      }
    }
  }

  .result-body {
    .write-button {
      background-color: var(--global-color-light);
    }
  }
}

.embedded-scan-page.trove-dark {
  --global-color-dark: #383634;
  --global-color-light: #b79d82;
  --text-color: white;

  background-color: var(--global-color-dark);

  .instructions-container {
    color: var(--text-color);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: var(--text-color);
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#b79d82, 0.5);

      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .write-button {
      background-color: var(--global-color-dark);
    }
  }
}

.embedded-scan-page.unismart {
  header {
    justify-content: center;
    .logo-container {
      padding: 10px;
      img {
        height: 30px;
        margin-top: 5px;
      }
    }
    .title-container {
      display: none;
    }
  }

  .instructions-container {
    color: black;
  }

  .scan-button-container {
    .scan-button {
      background-color: #1b451a;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#1b451a, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #338832;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: #1b451a;
    color: #fff;
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    font-family: "CooperBlack";
    background-color: #fdfdfd;
    background-image: linear-gradient(
      #1b451a,
      #1b451a 100px,
      transparent 100px
    );

    .header {
      &.success {
        color: #399034 !important;
      }
    }

    .body {
      font-weight: 200;
    }

    .title,
    .subtitle {
      white-space: pre-wrap;
      font-weight: 200;
    }
    .footnote {
      display: none;
    }
    .image-wrapper {
      margin-top: 10px;
      .product-image {
        height: 200px;
      }
    }
  }
}

.embedded-scan-page.unismart-auth {
  header {
    background-color: white;
    .logo-container {
      padding: 10px;
      padding-right: 40px;
      img {
        height: 30px;
        margin-top: 5px;
      }
    }
  }

  .instructions-container {
    color: black;
  }

  .scan-button-container {
    .scan-button {
      background-color: #1b451a;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#1b451a, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #338832;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: #1b451a;
    color: #fff;
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    font-family: "CooperBlack";
    background-color: #fdfdfd;
    background-image: linear-gradient(
      #1b451a,
      #1b451a 100px,
      transparent 100px
    );

    .header {
      &.success {
        color: #399034 !important;
      }
    }

    .body {
      font-weight: 200;
    }

    .title {
      margin-top: 0px;
      margin-bottom: 6px;
      font-size: 1.4em;
      text-align: left;
    }
    .subtitle {
      white-space: pre-wrap;
      text-align: left;
      font-size: 1em;
    }
    .footnote {
      display: none;
    }
    .image-wrapper {
      padding: 6px 12px;
    }
  }
}

.embedded-scan-page.sicpa {
  --global-color-dark: #033b5a;
  --global-color-light: #033b5a;

  header {
    background-color: var(--global-color-light);
    color: white;
  }

  .help-link {
    background-color: var(--global-color-light);
    color: white;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#033b5a, 0.5);

      &.loading {
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
  }
}

.embedded-scan-page.tscl {
  header {
    .logo-container {
      display: none;
    }
    .title-container {
      .title {
        margin-left: 0px;
      }
    }
  }

  --global-color-dark: white;
  --global-color-light: #122e4d;
  --text-color: black;

  background-color: var(--global-color-dark);

  .link-button {
    border: 2px solid var(--global-color-light);
    color: var(--text-color);
  }

  .instructions-container {
    max-width: 400px;
    color: var(--text-color);
    margin-left: auto;
    margin-right: auto;
    .step {
      white-space: pre-wrap;
      text-align: left;
    }
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#122e4d, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      margin-top: 0px;
      margin-bottom: 6px;
      font-size: 1.4em;
      text-align: left;
    }
    .subtitle {
      white-space: pre-wrap;
      text-align: left;
      font-size: 1em;
    }
    .footnote {
      display: none;
    }
    .image-wrapper {
      padding: 6px 12px;
    }
  }
}

.embedded-scan-page.vinsak {
  --global-color-dark: #0282ca;
  --global-color-light: #0282ca;

  header {
    .logo-container {
      padding: 10px;
      padding-right: 40px;
      img {
        height: 20px;
        margin-top: 10px;
      }
    }
  }

  .help-link {
    background-color: var(--global-color-light);
    color: white;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#0282ca, 0.5);

      &.loading {
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
  }
}

.embedded-scan-page.wireframe {
  --global-color-dark: black;
  --global-color-light: grey;

  .instructions-container {
    color: var(--global-color-dark);
  }

  .help-link {
    background-color: var(--global-color-light);
    color: white;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(black, 0.5);

      &.loading {
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-dark),
      var(--global-color-dark) 100px,
      transparent 100px
    );
    .read {
      color: var(--global-color-dark);
    }
    .write-button {
      background-color: var(--global-color-light);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-light);
      }
    }
  }

  .result-body {
    .title {
      color: var(--global-color-dark);
    }
    .write-button {
      background-color: var(--global-color-light);
    }
    .footnote {
      display: none;
    }
    .image-wrapper {
      .link-icon {
        display: none;
      }
    }
  }
}

.embedded-scan-page.wechat {
  header {
    display: none;
  }

  --global-color-dark: white;
  --global-color-light: #122e4d;
  --text-color: black;

  background-color: var(--global-color-dark);

  .link-button {
    border: 2px solid var(--global-color-light);
    color: var(--text-color);
  }

  .instructions-container {
    max-width: 400px;
    color: var(--text-color);
    margin-left: auto;
    margin-right: auto;
    .step {
      white-space: pre-wrap;
      text-align: left;
    }
  }

  .help-link {
    background-color: var(--global-color-light);
    color: #ffffff;
  }

  .scan-button-container {
    .scan-button {
      background-color: var(--global-color-light);
      box-shadow: 0 0 0 0 rgba(#122e4d, 0.5);
      color: #ffffff;
      &.loading {
        border: 6px solid var(--global-color-dark);
        border-top: 6px solid var(--global-color-light);
      }
    }
  }

  .result-container {
    &.fullImage {
      background-image: none;
      background-color: white;
    }
    background-image: linear-gradient(
      var(--global-color-light),
      var(--global-color-light) 100px,
      transparent 100px
    );
    .read {
      color: var(--global-color-dark);
    }
    .write-form-container {
      .write-button-save {
        color: var(--global-color-dark);
      }
    }
  }

  .result-body {
    .title {
      margin-top: 0px;
      margin-bottom: 6px;
      font-size: 1.4em;
      text-align: left;
    }
    .subtitle {
      white-space: pre-wrap;
      text-align: left;
      font-size: 1em;
    }
    .footnote {
      display: none;
    }
    .image-wrapper {
      padding: 6px 12px;
    }
  }
}

@keyframes buttonpulse {
  70% {
    box-shadow: 0 0 0 50px rgba(#2e71b6, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(#2e71b6, 0);
  }
}
