@import "SharedStyles/Fonts.scss";

.embedded-scan-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .instructions-container {
    color: black;
  }

  .scan-button-container {
    .scan-button {
      background-color: #3484f7;
      color: #fff;
      box-shadow: 0 0 0 0 rgba(#3484f7, 0.5);

      &.loading {
        width: 109px;
        height: 109px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #3498db;
        animation: spin 2s linear infinite;
      }
    }
  }

  .help-link {
    background-color: #3484f7;
    color: #fff;
  }

  .result-container {
    background-color: #fdfdfd;
  }
}

@keyframes buttonpulse {
  70% {
    box-shadow: 0 0 0 50px rgba(#3484f7, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(#3484f7, 0);
  }
}
