header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  height: 60px;
  display: flex;
  flex-direction: row;
  z-index: 10;
  box-shadow: 0px 2px 5px rgba(114, 114, 114, 0.4);

  &.is-android{
    top: 45px;
  }

  .logo-container {
    // flex-basis: 40px;
    padding: 10px;
    img {
      width: auto;
      height: 40px;
    }
  }

  .title-container {
    font-family: var(--global-font);
    flex: 3;

    .title {
      text-align: center;
      margin-left: -60px;
    }
  }
}
