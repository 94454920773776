:root {
  --name-font-size: 100%;
  --button-font-size: 70%;
  --main-width: 80%;
}

.main-container {
  padding: 0px;
  width: var(--main-width);
  margin: auto;
  .container {
    display: flex;
    justify-content: space-between;
    .name {
      font-size: var(--name-font-size);
      margin-bottom: 2px;
    }
    .button {
      font-size: var(--button-font-size);
      background-color: transparent;
      border-color: transparent;
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .body {
    border: 1px solid lightgray;
    border-radius: 22px;
    margin: auto;
    .body-text {
      white-space: pre-wrap;
      text-align: left;
      padding-left: 10%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
