.spinner {
  margin: auto;
  background-color: #fff;
  color: #666666;
  width: 109px;
  height: 109px;
  border-radius: 50%;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #666666;
  animation: spin 2s linear infinite;
  margin-top: 12px;
}
